import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocItem from "../../components/api-doc-item"
import ApiDocFooter from "../../components/api-doc-footer"
import ApiParameterTable from "../../components/api-parameter-table"
import ApiKeyField from "../../components/api-key-field"
import CodeBlock from "../../components/code-block"
import CodeTransformer from "../../components/code-transformer"
import Table from "../../components/table"
import ApiParameterItem from "../../components/api-parameter-item"
import apiSettings from "../../utils/api-settings"
import Transformer from "../../utils/code-transformer/transformer"

const endpoint = "play/file"

const transformer = new Transformer({
  method: "postWithFile",
  options: {
    endpoint: endpoint,
    apiBaseUrl: apiSettings.apiBaseUrl,
    apiKey: apiSettings.apiKey,
  },
})

const ApiPlayFilePage = () => (
  <Layout title="Play File API" sidebar={ApiSidebar()}>
    <SEO title="Play File API" />

    <p>
      Use the <i>Play File API</i> to play local audio files with optional
      effects on your Sonos speakers. Just replace{" "}
      <code>{apiSettings.apiKeyPlaceholder}</code> with your own key.
    </p>

    <p>
      To use the <i>Play File API</i> you need credits. One credit is necessary
      to play a single file.{" "}
      <Link to="/pricing/">Learn more about credits here.</Link> No credits are
      charged when previewing clips.
    </p>

    <ApiKeyField />

    <ApiDocItem>
      <h3>Endpoint</h3>
      <CodeBlock
        platform="bash"
        code={{
          bash: {
            code: `POST  ${apiSettings.apiBaseUrl}/${endpoint}`,
            codeToCopy: `${apiSettings.apiBaseUrl}/${endpoint}`,
          },
        }}
        showPlatformPicker={false}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Basic example</h3>
      <p>
        This will play the given file with your default settings on your Sonos
        speakers. <code>params</code> and <code>file</code> are the only
        required parameters.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {},
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Use intro and outro sounds</h3>
      <p>
        This will play the defined intro and outro sounds. You can set{" "}
        <code>intro</code> and/or <code>outro</code> to a sound from{" "}
        <Link to="/api/list-sounds/">this list </Link>. You can pass{" "}
        <code>null</code> if you don't want to use a intro/outro sound to
        override your global default.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              intro: "quakquak",
              outro: null,
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set volume & speakers</h3>
      <p>
        Set <code>targets</code> (speakers, groups, ...) and <code>volume</code>{" "}
        to override your global defaults.{" "}
        <Link to="/api/list-targets/">
          Get a list of all available targets.
        </Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              targets: "livingroom",
              volume: 10,
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play on multiple targets</h3>
      <p>
        To play on multiple targets at the same time, set the{" "}
        <code>targets</code> parameters like this.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              targets: ["livingroom", "bath"],
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play with audio effects</h3>
      <p>
        Apply <code>speed</code> and <code>reverb</code> audio effects to the
        file itself. Learn more about effects in the <i>Request Parameters</i>{" "}
        section below.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              speed: 30,
              reverb: 50,
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Preview mode</h3>
      <p>
        If you just want to preview the playback, you can pass{" "}
        <code>preview</code> and nothing will be played on your Sonos speakers.
        But the JSON response will include an URL to the audio file.{" "}
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              preview: true,
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play only on specific days and times</h3>
      <p>
        Use the <code>days</code> and <code>hours</code> parameters to specify
        when to play the notification. If week day and time (
        <a href="https://app.quak.sh/settings" target="_blank">
          check your time zone
        </a>
        ) does not match nothing will be played. Notifications will not be
        queued.
      </p>
      <p>
        Let's say you don't want to play notifications in the night because...
        well... you are sleeping. It would be a waste of energy and Quak credits
        if the notification would be played 24/7.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            params: {
              days: "mo-fr",
              hours: "8-18",
            },
            file: "/audio/lion.mp3",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Request Parameters</h3>
      <p>
        To send your audio file including all necessary parameters to the Quak
        API server the <i>Play File API</i> expects{" "}
        <code>multipart/form-data</code> (
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects"
          target="_blank"
          rel="noopener noreferrer"
        >
          learn more
        </a>
        ) with the following fields:
      </p>
      <Table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <ApiParameterItem parameter="params" type="json">
            All supported parameters (see table below) as JSON.{" "}
            <strong>
              This parameter is required even if no parameters are set.
            </strong>
          </ApiParameterItem>
          <ApiParameterItem parameter="file" type="binary">
            The file you want to play. Max file size: 1 MB, supported file
            formats: mp3 (preferred), wav, ogg.{" "}
            <strong>This parameter is required.</strong>
          </ApiParameterItem>
        </tbody>
      </Table>

      <p>This is a full list of parameters you can use:</p>

      <ApiParameterTable type="FILE" />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Example Response</h3>
      <p>
        If the request was successful you will receive a response like this one.
        The <i>uuid</i> is the unique identifier you can use to get updates
        regarding playback status. Directly after submitting <i>status</i> is{" "}
        <b>PENDING</b>. Only if you use preview mode the <i>status</i> is{" "}
        <b>DONE</b>.
      </p>
      <CodeBlock
        platform="json"
        code={{
          json: {
            code: `{
  "uuid": "1ee783c0-871e-4d0b-b2c9-8a98689ac189",
  "createdAt": "2019-05-28T22:17:04.252Z",
  "type": "FILE",
  "status": "PENDING",
  "audioUrl": "https://quakquak.s3.eu-central-1.amazonaws.com/out/3ceb/9cfd/3ceb9cfd-59b0-4e1f-aa65-d92170fb04b3.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIV5WXHY5MSMXPN7Q%2F20190528%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20190528T221704Z&X-Amz-Expires=180&X-Amz-Signature=b451470dbb7b5f92f4581e89347900154f2210dab86893cd52751c399b398a17&X-Amz-SignedHeaders=host",
  "audioId": "3ceb9cfd-59b0-4e1f-aa65-d92170fb04b3",
  "audioUrlExpiresAt": "2019-05-28T22:20:04.415Z",
  "length": 1.36,
  "credits": 1,
  "fromCache": true,
  "players": [
    {
      "uuid": "f89fcd4d-05d1-4231-a954-ca5f5cead5a1",
      "slug": "livingroom",
      "name": "Livingroom",
      "provider": "SONOS",
      "status": "PENDING"
    }
  ],
  "params": {
    "intro": null,
    "outro": null,
    "delay": 200,
    "volume": 30,
    "targets": [
      "livingroom"
    ],
    "speed": 0,
    "tempo": 0,
    "pitch": 0,
    "reverb": 0,
    "reverse": false,
    "preview": false,
    "priority": false,
    "skipCache": false
  }
}
`,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocFooter active="/api/play-file/" />
  </Layout>
)

export default ApiPlayFilePage
